import React from "react"
import { Link } from "gatsby"
import Box from "./Box"

const Header = () => {
  return (
    <Box style={styles.header}>
      <Link style={styles.logoText} to="/">
        <img style={styles.logoImg} src="/litso.svg" alt="logo" />
        Litso
      </Link>
    </Box>
  )
}

export default Header

const styles = {
  header: {},
  logoText: {
    fontSize: 38,
    color: "white",

    padding: 20,
    display: "flex",
    alignItems: "center",
  },
  logoImg: {
    marginRight: 20,
    height: 50,
  },
}

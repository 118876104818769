import React from "react"

const Divider = ({ style }) => {
  return (
    <div
      style={{
        ...styles.divider,
        ...(style ? style : {}),
      }}
    />
  )
}

export default Divider

const styles = {
  divider: {
    width: "100%",
    height: "1px",
    backgroundColor: "#fff",
    boxShadow: "rgba(220, 220, 220, 0.8) 0px -1px 0px",
  },
}

import React from "react"

const FlexV = ({ children, style }) => {
  return (
    <div
      style={{
        ...styles.flexDiv,
        ...(style ? style : {}),
      }}
    >
      {children}
    </div>
  )
}

export default FlexV

const styles = {
  flexDiv: {
    flex: 1,
  },
}

import React from "react"

const Box = ({ children, style }) => {
  return (
    <div
      style={{
        ...styles.box,
        ...(style ? style : {}),
      }}
    >
      {children}
    </div>
  )
}

export default Box

const styles = {
  box: {
    width: "100%",
    maxWidth: 960,
  },
}

import React from "react"
import { Link } from "gatsby"
import Box from "./Box"
import FlexV from "./Flex/FlexV"
import FlexH from "./Flex/FlexH"
import SectionH from "./Flex/SectionH"
import SectionV from "./Flex/SectionV"
import Spacer from "./Spacer"
import Divider from "./Divider"

const Footer = () => {
  return (
    <SectionV style={styles.footer}>
      <Box>
        <FlexH
          style={styles.sections}
          alignment="flex-start"
          justify="flex-end"
        >
          <FlexV style={styles.section} alignment="flex-start">
            <div style={styles.sectionTitle}>Legal</div>
            <Link style={styles.link} to="/terms">
              Terms
            </Link>
            <Link style={styles.link} to="/privacy">
              Privacy Policy
            </Link>
          </FlexV>

          <FlexV style={styles.section} alignment="flex-start">
            <div style={styles.sectionTitle}>Help</div>
            {/* <Link style={styles.link} to="/faq">
              FAQs
            </Link> */}
            <a style={styles.link} href="mailto:hello@litso.io">
              Contact us
            </a>
          </FlexV>

          <FlexV style={styles.section} alignment="flex-start">
            <div style={styles.sectionTitle}>Follow us</div>
            <a
              style={styles.link}
              href="https://twitter.com/litso_app"
              target="_blank"
              rel="noreferrer"
            >
              <img
                style={styles.linkIcon}
                src="/social/twitter.svg"
                alt="twitter"
              />
              Twitter
            </a>
            <a
              style={styles.link}
              href="https://www.instagram.com/litso_app"
              target="_blank"
              rel="noreferrer"
            >
              <img
                style={styles.linkIcon}
                src="/social/instagram.svg"
                alt="instagram"
              />
              Instagram
            </a>
            <a
              style={styles.link}
              href="https://www.tiktok.com/@litso_app"
              target="_blank"
              rel="noreferrer"
            >
              <img
                style={styles.linkIcon}
                src="/social/tiktok.svg"
                alt="tiktok"
              />
              TikTok
            </a>
            <a
              style={styles.link}
              href="https://www.snapchat.com/add/litso_app"
              target="_blank"
              rel="noreferrer"
            >
              <img
                style={styles.linkIcon}
                src="/social/snapchat.svg"
                alt="snapchat"
              />
              Snapchat
            </a>
          </FlexV>
        </FlexH>
      </Box>
      <Divider />
      <SectionH>
        <Box style={styles.copyrights}>
          <FlexH>
            <p style={styles.copyrightsCompany}>
              © 2021 Litso AI. All rights reserved.
            </p>
            <p style={styles.copyrightsMade}>Made with ♥ in Paris</p>
            <Spacer />
          </FlexH>
        </Box>
      </SectionH>
    </SectionV>
  )
}

export default Footer

const styles = {
  footer: {
    color: "#7b7dab",
    backgroundColor: "#fff",
  },
  sections: {
    padding: 40,
  },
  section: {
    marginRight: 75,
  },
  links: {
    flex: 1,
    padding: 20,
    display: "flex",
    justifyContent: "flex-end",
  },
  sectionTitle: {
    fontWeight: "bold",
    fontSize: "0.85em",
    color: "#535252",
    marginBottom: 25,
  },
  link: {
    color: "#7b7dab",
    marginBottom: 10,
  },
  linkIcon: {
    display: "inline",
    height: "1em",
    marginRight: 10,
    opacity: 0.8,
  },
  copyrights: {
    padding: 20,
  },
  copyrightsCompany: {
    flex: 1,
  },
  copyrightsMade: {
    flex: 1,
    textAlign: "center",
  },
}

import React from "react"
import Header from "./Header"
import Footer from "./Footer"
import colors from "../constants/colors"

const Layout = ({ children }) => {
  return (
    <div style={styles.layout}>
      <Header />

      {children}

      <Footer />
    </div>
  )
}

export default Layout

const styles = {
  layout: {
    width: "100%",

    background: `url('shape.svg') top right no-repeat, linear-gradient(to bottom right, ${colors.purple}, ${colors.lightblue}) 100%`,

    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}

import React from "react"

const Flex = ({
  children,
  style,
  alignment = "center",
  justify = "center",
  ...props
}) => {
  return (
    <div
      {...props}
      style={{
        ...styles.flexDiv,
        alignItems: alignment,
        justifyContent: justify,
        ...(style ? style : {}),
      }}
    >
      {children}
    </div>
  )
}

export default Flex

const styles = {
  flexDiv: {
    display: "flex",
    maxWidth: "100%",
  },
}

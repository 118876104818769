import React from "react"
import FlexH from "./FlexH"

const SectionH = ({ children, style, ...props }) => {
  return (
    <FlexH
      {...props}
      style={{
        ...styles.flexDiv,
        ...(style ? style : {}),
      }}
    >
      {children}
    </FlexH>
  )
}

export default SectionH

const styles = {
  flexDiv: {
    width: "100%",
  },
}

import React from "react"
import Flex from "./Flex"

const FlexV = ({ children, style, ...props }) => {
  return (
    <Flex
      {...props}
      style={{
        ...styles.flexDiv,
        ...(style ? style : {}),
      }}
    >
      {children}
    </Flex>
  )
}

export default FlexV

const styles = {
  flexDiv: {
    flexDirection: "column",
  },
}

import React from "react"
import FlexV from "./FlexV"

const SectionV = ({ children, style, ...props }) => {
  return (
    <FlexV
      {...props}
      style={{
        ...styles.flexDiv,
        ...(style ? style : {}),
      }}
    >
      {children}
    </FlexV>
  )
}

export default SectionV

const styles = {
  flexDiv: {
    width: "100%",
  },
}

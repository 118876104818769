import React from "react"
import Flex from "./Flex"

const FlexH = ({ children, style, ...props }) => {
  return (
    <Flex
      {...props}
      style={{
        ...styles.flexDiv,
        ...(style ? style : {}),
      }}
    >
      {children}
    </Flex>
  )
}

export default FlexH

const styles = {
  flexDiv: {
    flexWrap: "wrap",
  },
}
